import React from "react"
import PropTypes from "prop-types"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import HotPepperEatingRegistrationForm from "../../../components/HotPepperEatingRegistrationForm/HotPepperEatingRegistrationForm"

// page styles
import onlineFormStyle from "assets/jss/material-kit-pro-react/views/hotPepperEatingRegistrationPageSections/hotPepperEatingFormStyle.jsx"
const useStyles = makeStyles(onlineFormStyle)

export default function HotPepperEatingForm(props) {
  const {
    handleSubmit,
    handleNext,
    formData,
    setFormData,
    setFormCompleted,
    formError,
    setFormError,
  } = props
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12}>
            <h2 className={classes.title}>Register Now</h2>
            <HotPepperEatingRegistrationForm
              handleSubmit={handleSubmit}
              handleNext={handleNext}
              formData={formData}
              setFormData={setFormData}
              setFormCompleted={setFormCompleted}
              formError={formError}
              setFormError={setFormError}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

HotPepperEatingForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleNext: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  setFormCompleted: PropTypes.func,
  formError: PropTypes.bool,
  setFormError: PropTypes.func,
}
