import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import Email from "@material-ui/icons/Email"
import FlagIcon from "@material-ui/icons/Flag"
import Home from "@material-ui/icons/Home"
import HomeWorkIcon from "@material-ui/icons/HomeWork"
import LocationCityIcon from "@material-ui/icons/LocationCity"
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox"
import Person from "@material-ui/icons/Person"
import PersonOutline from "@material-ui/icons/PersonOutline"
import Phone from "@material-ui/icons/Phone"
import registrationFormStyle from "assets/jss/material-kit-pro-react/components/registrationFormStyle.jsx"
import Autocomplete from "components/Autocomplete/Autocomplete.jsx"
import Button from "components/CustomButtons/Button.jsx"
import ErrorFocus from "components/ErrorFocus/ErrorFocus.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Danger from "components/Typography/Danger.jsx"
import { Field, Form, Formik } from "formik"
import { StatesList } from "helpers/Constants.jsx"
import React, { useEffect, useRef } from "react"
import * as Yup from "yup"

const useStyles = makeStyles(registrationFormStyle)
const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/

export default function HotPepperEatingRegistrationForm(props) {
  const {
    handleSubmit,
    handleNext,
    formData,
    setFormData,
    setFormCompleted,
    formError,
    setFormError,
  } = props
  const isMounted = useRef(true)
  const classes = useStyles()

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? "+1 " : ""
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
    }
    return phoneNumberString
  }

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const defaultValues = {
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phoneNumber: "",
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    zip: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid.")
      .required("Required"),
  })

  return (
    <>
      <h4 className={classes.title}>Information for Registration</h4>
      <p className={classes.requiredText}>* Indicates Required Field</p>
      <Formik
        initialValues={formData || defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!isMounted.current) return

          values.phoneNumber = formatPhoneNumber(values.phoneNumber)
          setFormData(values)
          setFormCompleted(true)
          handleSubmit()
          handleNext()

          if (isMounted.current) setSubmitting(false)
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={classes.container}>
              <GridContainer justifyContent="center">
                <GridItem md={5} sm={12} className={classes.inputItem}>
                  <Person className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    id="firstName"
                    name="firstName"
                    label="First Name *"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                    fullWidth
                  />
                </GridItem>
                <GridItem md={5} sm={12} className={classes.inputItem}>
                  <PersonOutline className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    id="lastName"
                    name="lastName"
                    label="Last Name *"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ""
                    }
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justifyContent="center">
                <GridItem md={10} sm={12} className={classes.inputItem}>
                  <Home className={classes.inputIcon} />
                  <Field
                    className={classes.formFieldWrapper}
                    id="address"
                    name="address"
                    component={Autocomplete}
                  />
                </GridItem>
                <GridItem md={10} sm={12} className={classes.inputItem}>
                  <HomeWorkIcon className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    autoComplete="new-address2"
                    id="address2"
                    name="address2"
                    label="Address Line 2"
                    value={values.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justifyContent="center">
                <GridItem md={4} sm={12} className={classes.inputItem}>
                  <LocationCityIcon className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    id="city"
                    name="city"
                    label="City *"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.city && touched.city ? true : false}
                    helperText={errors.city && touched.city ? errors.city : ""}
                    fullWidth
                  />
                </GridItem>
                <GridItem md={3} sm={12} className={classes.inputItem}>
                  <FlagIcon className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    id="state"
                    name="state"
                    label="State *"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.state && touched.state ? true : false}
                    helperText={
                      errors.state && touched.state ? errors.state : ""
                    }
                    fullWidth
                    select
                  >
                    {StatesList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </GridItem>
                <GridItem md={3} sm={12} className={classes.inputItem}>
                  <MarkunreadMailboxIcon className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    id="zip"
                    name="zip"
                    label="Postal Code/Zip *"
                    value={values.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.zip && touched.zip ? true : false}
                    helperText={errors.zip && touched.zip ? errors.zip : ""}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>

              <GridContainer justifyContent="center">
                <GridItem md={5} sm={12} className={classes.inputItem}>
                  <Email className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    id="email"
                    name="email"
                    type="email"
                    label="Email *"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                    fullWidth
                  />
                </GridItem>
                <GridItem md={5} sm={12} className={classes.inputItem}>
                  <Phone className={classes.inputIcon} />
                  <TextField
                    className={classes.formFieldWrapper}
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    label="Phone Number *"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.phoneNumber && touched.phoneNumber ? true : false
                    }
                    helperText={
                      errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : ""
                    }
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
              <GridContainer justifyContent="center">
                <GridItem md={8} sm={12}>
                  <p className={classes.description}>
                    <b>Questions?</b> <br />
                  </p>
                  <ul>
                    <li className={classes.description}>
                      Contact:{" "}
                      <a href="mailto:cookoff@chilimania.com">
                        cookoff@chilimania.com
                      </a>{" "}
                    </li>
                  </ul>
                </GridItem>
              </GridContainer>
              {formError && (
                <GridContainer justifyContent="center">
                  <GridItem md={8} sm={12}>
                    <Danger>
                      We're sorry, we are unable to submit your registration
                      form. Refresh the page to try again. If possible, try
                      using a different browser (Chrome, Firefox, Safari) to
                      submit your registration. Please use the contact listed
                      above if you are still unable to successfully submit.
                    </Danger>
                  </GridItem>
                </GridContainer>
              )}
              <GridContainer justifyContent="center">
                <GridItem xs={12} className={classes.submitButton}>
                  <Button color="primary" type="submit">
                    Submit Registration
                    <ChevronRightIcon />
                  </Button>
                </GridItem>
              </GridContainer>
              <ErrorFocus />
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
